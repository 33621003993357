<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/home' }">店铺账户</el-breadcrumb-item>
      <el-breadcrumb-item>账户余额</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :row-key="(row) => row.id"
      >
        <el-table-column label="别名" prop="alisa" />
        <el-table-column label="币种" prop="currency" />
        <el-table-column label="链/协议" prop="chain" />
        <el-table-column label="地址" prop="address" />
        <el-table-column label="余额" prop="balance" />
        <el-table-column label="是否启用">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.isEnable">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >查看</el-button
            >
            <el-button type="text" size="small" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped></style>
