
var pad = (num, cover) => {
	return String("0".repeat(cover) + num).slice(-cover);
}
export default {
	pad,
	formatSeconds: (second) => {
		//  秒
		//  分
		let minute = 0
		//  小时
		let hour = 0
		//  天
		//  let day = 0
		//  如果秒数大于60，将秒数转换成整数
		if (second > 60) {
			//  获取分钟，除以60取整数，得到整数分钟
			minute = parseInt(second / 60)
			//  获取秒数，秒数取佘，得到整数秒数
			second = parseInt(second % 60)
			//  如果分钟大于60，将分钟转换成小时
			if (minute > 60) {
				//  获取小时，获取分钟除以60，得到整数小时
				hour = parseInt(minute / 60)
				//  获取小时后取佘的分，获取分钟除以60取佘的分
				minute = parseInt(minute % 60)
			}
		}
		let result = '' + pad(parseInt(second), 2) + ''
		if (minute > 0) {
			result = '' + pad(parseInt(minute), 2) + ':' + result
		} else {
			result = '00:' + result
		}
		if (hour > 0) {
			result = '' + pad(parseInt(hour), 2) + ':' + result
		} else {
			result = '00:' + result
		}
		//  if (day > 0) {
		//    result = '' + parseInt(day) + '天' + result
		//  }
		return result
	},
	// 初始化时间
	initTime: () => {
		Date.prototype.format = function (fmt) {
			let o = {
				"M+": this.getMonth() + 1, //月份 
				"d+": this.getDate(), //日 
				"h+": this.getHours(), //小时 
				"m+": this.getMinutes(), //分 
				"s+": this.getSeconds(), //秒 
				"q+": Math.floor((this.getMonth() + 3) / 3), //季度 
				"S": this.getMilliseconds() //毫秒 
			};

			if (/(y+)/.test(fmt)) {
				fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
			}

			for (let k in o) {
				if (new RegExp("(" + k + ")").test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" +
						o[k]).length)));
				}
			}

			return fmt;
		}
	},

	// 时间戳转换
	formatterDate: (date, patten) => {
		let time = date.getTime()
		let day = new Date(time).format(patten)

		return day.toLocaleString()
	},

	// 生成UUID
	createUUID: (num) => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * num | 0,
				v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(num)
		});
	},
	numberToCurrencyNo(value) {
		if (!value) return 0
		// 获取整数部分
		const intPart = Math.trunc(value)
		// 整数部分处理，增加,
		const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
		// 预定义小数部分
		let floatPart = ''
		// 将数值截取为小数部分和整数部分
		const valueArray = value.toString().split('.')
		if (valueArray.length === 2) {
			// 有小数部分
			floatPart = valueArray[1].toString() // 取得小数部分
			return intPartFormat + '.' + floatPart
		}
		return intPartFormat + floatPart
	},
}
