<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商户管理</el-breadcrumb-item>
      <el-breadcrumb-item>交易单</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="时间范围:">
              <el-date-picker v-model="value2" type="datetimerange" :picker-options="pickerOptions" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" align="right"
            @change="handleDateChange">
                </el-date-picker>
             </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="客户编号:">
              <el-input placeholder="请输入客户编号" v-model="customerNo" class="input-with-select"
            @keyup.enter.native="handleCustomerChange">
          </el-input>
             </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="选择商家:">
              <el-select v-model="tenantPopover" @change="_getstoreData" placeholder="请选择">
                      <el-option v-for="(item, index) in tenantList" :key="index" :label="item.tenant_name"
                        :value="item.tenant_id"></el-option>
                    </el-select>
             </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="选择店铺:">
              <el-select @change="handleStoreChange"  v-model="storePopover" multiple collapse-tags  placeholder="请选择">
                <el-option v-for="item in storeList" :key="item.value" :label="item.store_name" :value="item.store_id">
                </el-option>
              </el-select>
             </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="状态:">
              <el-select clearable v-model="statusPopover"  placeholder="请选择">
                      <el-option v-for="(item, index) in statusList" :key="index" :label="item.status_name"
                        :value="item.status"></el-option>
                    </el-select>
             </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="卖出币种:" >
              <el-row>
                <el-col :span="12">
                  <el-select clearable v-model="maichubizhongPopover"  placeholder="请选择">
                      <el-option v-for="(item, index) in mairubizhongList" :key="index" :label="item.currency_name"
                        :value="item.currency_id"></el-option>
              </el-select>
                </el-col>
                <el-col :span="12">
                  <el-input placeholder="请输入卖出金额" type="number" v-model="maichupricePopover" class="input-with-select">
                  </el-input> 
                </el-col>
              </el-row>
                    
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="买入币种:" >
              <el-row>
                <el-col :span="12">
                  <el-select clearable  v-model="mairubizhongPopover"  placeholder="请选择">
                      <el-option v-for="(item, index) in mairubizhongList" :key="index" :label="item.currency_name"
                        :value="item.currency_id"></el-option>
              </el-select>
                </el-col>
                <el-col :span="12">
                  <el-input placeholder="请输入买入金额" type="number" v-model="mairupricePopover" class="input-with-select">
                  </el-input> 
                </el-col>
              </el-row>   
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="交易类型:">
              <el-select clearable v-model="jiaoyileixingPopover"  placeholder="请选择">
                      <el-option v-for="(item, index) in jiaoyileixingList" :key="index" :label="item.name"
                        :value="item.type"></el-option>
                    </el-select>
             </el-form-item>
          </el-col>
          <el-col :span="4">
            <!-- <el-button icon="el-icon-search" circle @click="getData"> -->
              <el-button type="primary" @click="getData2" plain>搜索</el-button>
              <el-button type="success"  @click="exportExcel" plain>导出</el-button>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: right;">
            <el-button type="warning" @click="handleSendSelectedData">批量隐藏</el-button>
          </el-col>
        </el-row>
             
</el-form>
  
      <el-table :data="tableData" border style="width: 100%" :row-key="(row) => row.id" class="custom-table"
        ref="multipleTable">
        <!-- 帮我在这里加一个多选 写一个事件拿到他所选择行的信息-->
      
        <el-table-column prop="store_name" label="店铺" align="center"></el-table-column>
        <el-table-column prop="store_exchange_id" label="单号" align="center">
        </el-table-column>
        <el-table-column label="客户" align="center">
          <template slot-scope="scope">
            <span>
              {{ scope.row.customer_name }}{{ scope.row.customer_id }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="买入" align="center">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.buy_amount)
              }}{{ scope.row.buy_currency_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="卖出" align="center">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.sale_amount)
              }}{{ scope.row.sale_currency_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="汇率" align="center">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.rate) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="汇率倒数" align="center">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.rate_reciprocal) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="exchange_type_name" label="交易类型" align="center" width="150">
        </el-table-column>
        <el-table-column prop="create_time" label="时间" width="110" align="center">
        </el-table-column>
        <el-table-column label="是否隐藏" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.hide_flag" active-color="#13ce66" inactive-color="#909399"
              @change="handleHideFlagChange2(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column type="selection"  width="80"></el-table-column>
        <el-table-column prop="status_name" label="状态" width="100" align="center">
        </el-table-column>
        <el-table-column width="100" align="center">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="showEditDialog(scope.row)" icon="el-icon-edit">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pageIndex" :page-sizes="[200, 400,]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>
    <el-dialog title="详细信息" width="1250px" :visible.sync="detailsWindow" center>
      <div class="detailsPop">
        <div>
          <div class="detailsList">
            <div class="item-info">
              <div class="item-title">
                <div>{{ detailsInfo.store_exchange_no }}</div>
                <div>
                  客户：{{ detailsInfo.customer_name }}({{
                    detailsInfo.customer_no
                  }})
                </div>
              </div>
              <div class="item-content">
                <div>
                  <div>
                    买入：{{ Number(detailsInfo.buy_amount)
                    }}{{ detailsInfo.buy_currency_name }}
                  </div>
                  <div>
                    卖出：{{ Number(detailsInfo.sale_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>买汇价：{{ detailsInfo.rate * 1 }}</div>
                </div>
                <div v-if="
                  detailsInfo.exchange_type != 'Cash2Cash' &&
                  detailsInfo.exchange_type != 'Surplus2Cash'
                ">
                  <div>
                    手续费：{{ Number(detailsInfo.fee_amount)
                    }}{{ detailsInfo.fee_currency_name }}
                  </div>
                  <div>
                    自带：{{ Number(detailsInfo.bring_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>
                    自取：{{ Number(detailsInfo.take_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                </div>
                <div v-if="
                  detailsInfo.exchange_type != 'Cash2Cash' &&
                  detailsInfo.exchange_type != 'Surplus2Cash'
                ">
                  <div>
                    应付：{{
                      Number(detailsInfo.sale_amount) +
                      Number(detailsInfo.bring_amount) -
                      Number(detailsInfo.take_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>
                    待付：{{ Number(detailsInfo.toBePaidAmount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                </div>
              </div>
              <div v-if="
                detailsInfo.exchange_type == 'Cash2Cash' ||
                detailsInfo.exchange_type == 'Surplus2Cash'
              ">
                <div></div>
                <div>状态：{{ detailsInfo.statusString }}</div>
              </div>
            </div>
            <template>
              <div class="header-title" v-if="
                detailsInfo.exchange_type != 'Cash2Cash' &&
                detailsInfo.exchange_type != 'Surplus2Cash'
              ">
                <div v-for="(item, index) in detailsInfo.details" :key="'details' + index" class="item-ul">
                  <div class="line"></div>
                  <div class="item-li">
                    <div v-if="
                      detailsInfo.details && detailsInfo.details.length > 1
                    ">
                      第{{ index + 1 }}笔
                    </div>
                  </div>
                  <div class="item-li">
                    <div class="item-li">
                      <div>
                        收款人：{{ detailsInfo.customer_name }}({{
                          detailsInfo.customer_no
                        }})
                      </div>
                      <div v-for="(cItem, cIndex) in item.cards" :key="'cItem' + cIndex">
                        <div v-if="cItem.bank_card_type_code == 'BankCard'">
                          {{ cItem.holder_name }}
                          {{ cItem.bank_class_name }} ({{
                            cItem.bank_card_number
                          }})
                        </div>
                        <div v-if="
                          cItem.bank_card_type_code == 'WX' ||
                          cItem.bank_card_type_code == 'Alipay'
                        " class="paymentCode">
                          <div class="paymentCode-title">
                            {{ cItem.holder_name }}
                          </div>
                          <div class="paymentQrCode-box">
                            <div class="paymentQrCode">好友码：</div>
                          </div>
                          <div class="paymentQrCode-box">
                            <div class="paymentQrCode">付款码：</div>
                          </div>
                        </div>
                        <div v-if="cItem.bank_card_type_code == 'Virtual'" class="paymentCode">
                          <div class="paymentCode-title">
                            {{ cItem.holder_name }}
                          </div>
                          <div>协议：{{ cItem.network }}</div>
                          &nbsp; &nbsp;
                          <div>地址：{{ cItem.qr_code }}</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      金额：{{ Number(item.amount) }}&nbsp; &nbsp;{{
                        detailsInfo.sale_currency_code
                      }}
                    </div>
                  </div>

                  <div v-if="item.pamentDetails && item.pamentDetails.length > 0">
                    <div v-for="(detail, dIndex) in item.pamentDetails" :key="'detail' + dIndex" class="itembox">
                      <div class="item-li">
                        <div v-if="!detail.payment_customer_name">
                          付款人：{{ detail.payment_client_id || "" }}
                          {{ detail.payment_tenant_name }}
                          {{ detail.payment_store_name }}
                          <text v-if="detail.bank_card_type_code == 'BankCard'">({{ detail.bank_card_number }})</text>
                        </div>
                        <div v-else>
                          付款人：{{ detail.holder_name || "" }}
                          {{ detail.bank_class_name }}
                          <text v-if="detail.bank_card_type_code == 'BankCard'">({{ detail.bank_card_number }})</text>
                        </div>
                        <div>
                          金额：{{ Number(detail.amount)
                          }}{{ detail.currency_name }}
                        </div>
                      </div>
                      <div class="item-li">
                        <div>时间：{{ detail.payment_time }}</div>
                        <div>状态：{{ detail.statusName }}</div>
                      </div>
                      <div class="item-li attachBtn">
                        <div class="attachment">
                          <AttachmentPreview :uploaded="detail.attachments" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as signalR from "@microsoft/signalr";
import * as XLSX from "xlsx";
import AttachmentPreview from "@/components/AttachmentPreview.vue";
export default {
  components: { AttachmentPreview },
  data() {
    return {
      form:{},
      customerNo: "",
      //websocket 连接对象
      connection: null,
      //websocket 状态
      connection_status: "",
      pickerOptions: {
        shortcuts: [
    {
        text: "最近一周",
        onClick(picker) {
            const now = new Date();
            const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
            const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0);
            picker.$emit("pick", [start, end]);
        }
    },
    {
        text: "最近一个月",
        onClick(picker) {
            const now = new Date();
            const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
            const start = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), 0, 0, 0);
            picker.$emit("pick", [start, end]);
        }
    },
    {
        text: "最近三个月",
        onClick(picker) {
            const now = new Date();
            const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
            const start = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate(), 0, 0, 0);
            picker.$emit("pick", [start, end]);
        }
    }
],
      },
      value2: "",
      detailsWindow: false,
      detailsInfo: {},
      //查询条件
      queryInfo: {
        // 当前页数
        pageIndex: 1,
        // 每页显示多少数据
        pageSize: 200,
      },
      total: 0,
      setTime: this.getTimestampAtStartOfDay(),
      etTime: this.getEndOfDayTimestamp(),
      // 获取的列表数据
      tableData: [],
      tenantPopover: '',
      storePopover: [],
      statusPopover:'',
      pricePopover: "",
      mairupricePopover: "",
      maichupricePopover: '',
      mairubizhongPopover: '',
      maichubizhongPopover: '',
      jiaoyileixingPopover: '',
      tenantList: [],
      tenantInfo: {},
      storeList: [],
      storeInfo: { store_id: "", store_name: "全部" },
      statusList: [],
      priceList: ["0-1000", "1000-5000", "5000-10000", "10000-50000"],
      priceInfo: { remark: "", price_id: "", store_name: "全部" },
      maichubizhongInfo: { remark: "", price_id: "", currency_name: "全部" },
      maichubizhongList: ['卖出', '买入'],
      mairubizhongInfo: { remark: "", price_id: "", currency_name: "全部" },
      mairubizhongList: [],
      jiaoyileixingInfo: { remark: "", status: "", name: "全部" },

      jiaoyileixingList: [
        { name: "现金对现金", type: "Cash2Cash" },
        { name: "现金对汇款", type: "Cash2Trans" },
        { name: "余额对现金", type: "Surplus2Cash" },
        { name: "余额对汇款", type: "Surplus2Trans" },
      ],
      statusInfo: { remark: "", status: "", status_name: "全部" },
    };
  },
  mounted() {
    this.startConnection();

    this.getStatusList();
    this.getTenantList();
    // this.getData();
  },
  methods: {
    getSelectedRows() {
        const selectedRows = this.$refs.multipleTable.selection;
        return selectedRows;
    },
    handleSendSelectedData() {
        const selectedRows = this.getSelectedRows(); 
        const dataArray = selectedRows.map(row => (
          row.hide_flag = true,  
        {
            // 根据你的实际需求，提取每行需要发送的数据字段组成新的对象
            store_exchange_id: row.store_exchange_id,
            hide_flag: row.hide_flag,
            //...其他需要发送的字段
        }));
        this.handleHideFlagChange(dataArray);
    },

    async handleHideFlagChange(row) {
      const { data: res } = await this.$http.post("StoreExchange/SetHideFlag", {
        items: row
      });
      if (res.code == 1) {
        this.$message.success("修改成功");

        this.getData();
      } else {
        this.$message.error(res.message);
      }
    },
    async handleHideFlagChange2(row) {
      const { data: res } = await this.$http.post("StoreExchange/SetHideFlag", {
        items:[{
          store_exchange_id: row.store_exchange_id,
          hide_flag: row.hide_flag
        }]
      });
      if (res.code == 1) {
        this.$message.success("修改成功");

        this.getData();
      } else {
        this.$message.error(res.message);
      }
    },
    handleCustomerChange() {
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    startConnection() {
      // this.connection = new signalR.HubConnectionBuilder()
      //   .withUrl("http://192.168.0.195:8019/ChatHub", {
      //     accessTokenFactory: () => window.sessionStorage.getItem("token"),
      //   })
      //   .build();
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl("https://api.cash940.com/tenant-chat/ChatHub", {
          accessTokenFactory: () =>
            window.sessionStorage.getItem("token").replace("Bearer ", ""),
        })
        .build();
      // this.connection = new signalR.HubConnectionBuilder()
      //   .withUrl('ws://192.168.0.147:8018/ChatHub', {
      //     accessTokenFactory: () => window.sessionStorage.getItem("token"),
      //     skipNegotiation: true,
      //     transport: signalR.HttpTransportType.WebSockets
      //   })
      //   .configureLogging(signalR.LogLevel.Information)
      //   .build();

      this.connection
        .start()
        .then(() => {
          console.log("连接成功");
          this.connection_status = "连接成功";
        })
        .catch((error) => {
          console.error("连接失败: ", error);
          this.connection_status = "连接失败";
        });

      this.connection.on("ReceiveMessage", (message) => {
        console.log(message);
        // 假设有一个函数refreshData()用来刷新数据
        this.getData();
      });
      // 当连接断开时触发的处理函数
      this.connection.onclose((error) => {
        if (error) {
          this.connection_status = "连接断开，错误信息：" + error;
        } else {
          this.connection_status = "连接断开";
        }
        // 这里可以编写重新连接或其他逻辑
      });
    },
    async exportExcel() {
      const { data: res } = await this.$http.post("StoreExchange/Query", {
       
        buyCurrencyID:  this.mairubizhongPopover,
          saleCurrencyID: this.maichubizhongPopover,
          minBuyAmount: this.mairupricePopover,
          exchangeType: this.jiaoyileixingPopover,
          minSellAmount: this.maichupricePopover,
          tenant: this.tenantPopover,
          storeList: this.storePopover,
          st: this.setTime,
          et: this.etTime,
          customer: this.customerNo, 
          status: this.statusPopover,

          pageIndex: this.queryInfo.pageIndex,
          pageSize: this.total,
     
      });
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res);
      let dataList = [];

      if (Array.isArray(res.data)) {
        dataList = res.data
          .map((item) => {
            if (item && item.status !== "delete" && !item.hide_flag) {
              return {
                店铺: item.store_name || "",
                单号: item.store_exchange_no || "",
                客户:
                  (item.customer_name || "") +
                  "(" +
                  (item.customer_id || "") +
                  ")",
                买入: (item.buy_amount || 0) * 1,
                买入币种: item.buy_currency_name || "",
                卖出: (item.sale_amount || 0) * 1,
                卖出币种: item.sale_currency_name || "",
                汇率: (item.rate || 0) * 1,
                汇率倒数: (item.rate_reciprocal || 0) * 1,
                交易类型: item.exchange_type_name || "",
                时间: item.create_time || "",
                状态: item.status_name || "",
              };
            }
          })
          .filter(Boolean); // 过滤掉返回值为 undefined 的项
      } else {
        dataList = [];
      }
      let storeName = this.storeInfo.store_name;
      let time = this.setTime + "至" + this.etTime;
      // 将data数组转换为工作表
      const ws = XLSX.utils.json_to_sheet(dataList);

      // 创建一个新的工作簿
      const wb = XLSX.utils.book_new();

      // 将工作表添加到工作簿中
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // 生成Excel文件
      XLSX.writeFile(wb, storeName + "-交易单-" + time + ".xlsx");
    },

    // sendMessage() {
    //     if (this.connection) {
    //         this.connection.send('SendMessage', 'Hello, Server!');
    //     }
    // },
    getTimestampAtStartOfDay() {
      var now = new Date();
      now.setHours(0, 0, 0, 1); // 设置为当天的00:00:01
      var year = now.getFullYear();
      var month = ("0" + (now.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要+1
      var day = ("0" + now.getDate()).slice(-2);
      var hours = ("0" + now.getHours()).slice(-2);
      var minutes = ("0" + now.getMinutes()).slice(-2);
      var seconds = ("0" + (now.getSeconds() + 1)).slice(-2);

      return `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
    },
    getEndOfDayTimestamp() {
      const now = new Date();
      now.setHours(23, 59, 59, 999); // 将时、分、秒设置为23、59和59，毫秒设置为999以确保总是最后一秒
      var year = now.getFullYear();
      var month = ("0" + (now.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要+1
      var day = ("0" + now.getDate()).slice(-2);
      var hours = ("0" + now.getHours()).slice(-2);
      var minutes = ("0" + now.getMinutes()).slice(-2);
      var seconds = ("0" + now.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
    },
    getData2(){
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    async getData() {
      const { data: res } = await this.$http.post("StoreExchange/Query", {
       
          buyCurrencyID:  this.mairubizhongPopover,
          saleCurrencyID: this.maichubizhongPopover,
          minBuyAmount: this.mairupricePopover,
          exchangeType: this.jiaoyileixingPopover,
          minSellAmount: this.maichupricePopover,
          tenant: this.tenantPopover,
          storeList: this.storePopover,
          st: this.setTime,
          et: this.etTime,
          customer: this.customerNo, 
          status: this.statusPopover,

          pageIndex: this.queryInfo.pageIndex,
          pageSize: this.queryInfo.pageSize,
        
      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.forEach((row) => {
        if (row.hide_flag) {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        }
      });
      this.tableData = res.data;
      this.total = res.pageInfo.total;
    },
    // 获取状态列表
    async getStatusList() {
      const { data: res } = await this.$http.get("StoreExchange/GetStatus");
     
      
      this.statusList = res.data;

    },
    // 状态选择
    handleStatusChange(item) {
      this.statusInfo = item;
      this.queryInfo.pageIndex = 1;
      this.getData();
      this.statusPopover = false;
    },
    // 卖出币种
    handlemaichubizhongChange(item) {
      this.maichubizhongInfo = item;
      this.queryInfo.pageIndex = 1;
      this.getData();
      this.maichubizhongPopover = false;
    },
    // 卖入币种
    handleMairubizhongChange(item) {
      this.mairubizhongInfo = item;
      this.queryInfo.pageIndex = 1;
      this.getData();
      this.mairubizhongPopover = false;
    },
    // 交易类型的选择
    handleJiaoyileixingChange(item) {
      this.jiaoyileixingInfo = item;
      this.queryInfo.pageIndex = 1;
      this.getData();
      this.jiaoyileixingPopover = false;
    },
    // 获取商家列表
    getTenantList() {
      // 从 localStorage 中获取 'tenants' 的值
      const tenantsJson = localStorage.getItem("loginTenantsInfo");
      let tenants = [];
      if (tenantsJson) {
        // 将 JSON 字符串解析为 JavaScript 对象
        tenants = JSON.parse(tenantsJson);

        // 使用解析后的对象
        console.log("使用解析后的对象", tenants);
      } else {
        console.log("No tenants data found in local storage.");
      }
      this.tenantList = tenants;
      // this.tenantInfo = tenants[0];
      // this.getStoreList();
    },
    // 商家选择
    handleTenantChange(item) {
      this.tenantInfo = item;
      this.getStoreList();
      this.tenantPopover = false;
    },
    // 获取门店信息
    _getstoreData() {
      this.storePopover = []
      this.$http.get("v1/Store/GetStoreInfoByTenant", {
        params: { tenant: this.tenantPopover },
      }).then(({ data: res }) => {

        this.storeList = res.data;

      });
    },
    // 获取店铺列表
    async getStoreList() {
      const { data: res } = await this.$http.get(
        "v1/Store/GetStoreInfoByTenant",
        {
          params: { tenant: this.tenantInfo.tenant_id },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      this.storeList = res.data;
  
   
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    // 店铺选择
    handleStoreChange(item) {
      // 判断item是否为空
      if (item.length == 0) return;
    
        this._getbizhongList(item[0]);
      
    },
    // 币种数据
    _getbizhongList(e) {
      this.$http.get("/StoreCurrency/GetEnable", {
        params: {
          store: e,
          tenant: this.tenantPopover
        }
      }).then((res) => {
        if (res.code == -1) return this.$message.error(res.message);
        this.mairubizhongList = res.data.data;

      });
    },

    // 金额选择
    handlePriceChange(e) {
      this.priceInfo.store_name = e;
      this.queryInfo.pageIndex = 1;
      this.getData();
      this.pricePopover = false;
    },

    // 日期选择
    handleDateChange(e) {
      if (e) {
        let startDateStr = e[0].split(' ')[0];
       let endDateStr = e[1].split(' ')[0];
       let newStartDateStr = `${startDateStr} 00:00:00`;
        let newEndDateStr = `${endDateStr} 23:59:59`;

        this.value2 = [newStartDateStr, newEndDateStr];

        
        this.setTime = newStartDateStr;
        this.etTime = newEndDateStr;

      } else {
        this.setTime = this.getTimestampAtStartOfDay();
        this.etTime = this.getEndOfDayTimestamp();
      }

    },
    query() {
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    async showEditDialog(row) {
      console.log("编辑数据", row);
      const { data: res } = await this.$http.get("StoreExchange/GetDetail", {
        params: {
          ExchangeID: row.store_exchange_id,
        },
      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.toBePaidAmount = 0;
      if (res.data && res.data.details && res.data.details.length > 0) {
        res.data.details.forEach((item) => {
          res.data.toBePaidAmount += Number(item.wait_amount);
          if (item.pamentDetails && item.pamentDetails.length > 0) {
            item.pamentDetails.forEach((cItem) => {
              if (cItem.status == "agree") {
                cItem.statusName = "已同意收款";
              } else if (cItem.status == "wait") {
                cItem.statusName = "待确认收款";
              } else {
                cItem.statusName = "已拒绝收款";
              }
              cItem.uploadPath = [];
              if (cItem.attachments && cItem.attachments.length > 0) {
                cItem.attachments.forEach((attach) => {
                  cItem.uploadPath.push(attach.file_key);
                });
              }
            });
          }
        });
      }
      this.detailsInfo = res.data;
      this.detailsWindow = true;
    },
    //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    async showAttachment() {
      const { data: res } = await this.$http.get(
        "v1/Store/GetStoreInfoByTenant",
        {
          params: { storeExchangeId: this.detailsInfo.store_exchange_id },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res);
      this.$utils.showToast(res.message);
      if (res.code != 1) return;
      if (res.data && res.data.length > 0) {
        this.appendList = res.data;
        this.appendShow = true;
      } else {
        this.$utils.showToast("未添加附件！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search-box {
  display: flex;
  flex-wrap: wrap;

  align-content: space-around;

  

  .exportExcelBtn {
    margin: 0 50px;
  }
}

.popover-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;

  >div {
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}

.el-table {
  /deep/ .s_s {
    background: #228b22;
    color: white;
  }

  /deep/ .t_s {
    background: #6b8e23;
    color: white;
  }
}

.custom-table ::v-deep .el-table__cell {
  font-size: 16px !important;
}

.detailsPop {
  font-size: 16px;

  /deep/.u-transition {
    z-index: 996 !important;
  }

  .detailsList {
    width: 1200px;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 2px solid #000;

    .item-info {
      border-bottom: 2px solid #000;

      .item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;
      }

      .item-content {
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >view {
          >view {
            padding: 2px 0;
          }
        }
      }
    }

    .header-title {
      max-height: 500px;
      overflow: auto;
      box-sizing: border-box;

      .item-ul {
        margin: 5px 0;
        border-bottom: 1px solid #8e8c8c;

        .itembox {
          //虚线
          border-top: 1px dashed #7a7a7a;
        }

        .item-li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 0;

          .paymentCode {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .paymentCode-title {
              width: 130px;
            }

            .paymentQrCode-box {
              display: flex;
              align-items: center;
            }

            .paymentQrCode {
              width: 70px;
            }

            .component-container {
              width: 40%;
            }
          }
        }

        .attachBtn {
          .attachment {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 10px 0;

            >div {
              display: flex;
              align-items: center;
            }
          }
        }

        .button {
          .u-button {
            margin: 0 10px;
          }
        }
      }
    }
  }
}


/deep/.el-checkbox__inner {
  width: 50px; /* 调整宽度 */
  height: 50px; /* 调整高度 */
}
</style>
