<!--
作用：登录（仅仅密码）
auth：xzl 2022-7-22 -->
<template>
  <div class="login_container">
    <div
      style="
        background-color: #050608;
        width: 100%;
        height: 100%;
        overflow: hidden;
      "
    >
      <star-background />

      <div class="login_box">
        <!-- 头像区 -->
        <div class="title" style="margin-top: 5px">
          <img src="@/assets/logo.png" /><span>CASH940管理平台</span>
        </div>
        <el-form
          class="login_form"
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginFormRules"
        >
          <div class="row">
            <el-form-item label-width="0" prop="countryCode" style="width: 30%">
              <el-select
                v-model="loginForm.countryCode"
                placeholder="请选择国家/地区"
                @change="onCountryChange"
              >
                <el-option
                  v-for="country in nationList"
                  :key="country.country_code"
                  :label="
                    country.country_name + ' ' + country.international_number
                  "
                  :value="country.country_code"
                  :selected="country.country_code === 'HK'"
                />
              </el-select>
            </el-form-item>
            <el-form-item label-width="0" prop="phoneNumber" style="width: 70%">
              <el-input
                v-model="loginForm.phoneNumber"
                prefix-icon="el-icon-mobile-phone"
                placeholder="填写手机号"
                clearable
                ref="phoneNumberInput"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label-width="0" prop="password">
            <el-input
              v-model="loginForm.password"
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="填写密码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="buttonrow">
              <el-button type="primary" @click="login" style="width: 48%"
                >进入</el-button
              >
              <el-button type="info" @click="resetLoginForm" style="width: 48%"
                >重填</el-button
              >
            </div>
          </el-form-item>
          <span
            style="
              display: block;
              margin-top: -5px;
              margin-bottom: 15px;
              margin-left: 3px;
              color: white;
              font-size: 14px;
            "
            >登录或完成注册即代表你同意<a
              target="_blank"
              href="https://sohu.com"
              style="color: #aeae13"
              >《服务协议》和《隐私政策》</a
            ></span
          >
        </el-form>
      </div>

      <div
        style="
          position: absolute;
          left: 50%;
          bottom: 1%;
          transform: translate(-50%);
          color: #bbbbbb;
          font-size: 18px;
        "
      >
        Copyright © 2023 香港飞鱼科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
import StarBackground from "../components/StarBackground.vue";
export default {
  beforeCreate: function () {
    document.getElementsByTagName("body")[0].className = "body-bg";
  },

  components: { StarBackground },

  data() {
    return {
      nationList: [], // 国家列表
      // 账号登录
      loginForm: {
        countryCode: "HK", // 默认选择香港
        grant_type: "phonepwd",
        phoneNumber: "",
        password: "",
        client_id: "platform",
        client_secret: "12345678",
      },
      // 表单验证
      loginFormRules: {
        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: this.validatePhoneNumber, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请填写密码", trigger: "blur" },
          {
            min: 2,
            max: 18,
            message: "长度在 2 到 18 个字符",
            trigger: "blur",
          },
        ],
        countryCode: [
          { required: true, message: "请选择国家/地区", trigger: "change" },
        ],
      },
      countries: [
        { code: "CN", name: "中国", regex: /^1[3-9]\d{9}$/ },
        { code: "HK", name: "香港", regex: /^\d{8}$/ }, // 香港手机号为8位数字
        { code: "US", name: "美国", regex: /^\d{10}$/ },
        // 添加更多国家的手机号验证规则
      ],
    };
  },
  mounted() {
    this.getNationList();
  },
  methods: {
    // 获取国家列表
    async getNationList() {
      // 获取国家列表
      const res = await this.$http.get("api/InternationalNumber");
      if (res.data.code == 1) {
        this.nationList = res.data.data;
        // 默认选中中国香港international_number === "852"
        this.loginForm.countryCode = "HK";
      } else {
        this.$message.error(res.message);
      }
    },
    // 切换国家
    onCountryChange(countryCode) {
      const country = this.nationList.find(
        (c) => c.country_code === countryCode
      );
      if (country) {
        this.loginForm.countryCode = countryCode;
      }

      // 重新设置验证规则
      this.loginFormRules.phoneNumber[1].validator = this.validatePhoneNumber;
      // 聚焦手机号输入框
      this.$nextTick(() => {
        this.$refs.phoneNumberInput.focus();
      });
    },
    // 手机号验证
    validatePhoneNumber(rule, value, callback) {
      if (
        !this.loginForm.countryCode ||
        !this.nationList.find(
          (c) => c.country_code === this.loginForm.countryCode
        )
      ) {
        callback(new Error("请选择国家/地区"));
      } else {
        const country = this.countries.find(
          (c) => c.code === this.loginForm.countryCode
        );
        if (!country.regex.test(value)) {
          callback(new Error("请输入有效的手机号"));
        } else {
          callback();
        }
      }
    },
    // 表单重置按钮
    resetLoginForm() {
      // resetFields：element-ui提供的表单方法
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      // 表单预验证
      // valid：bool类型
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return false;
        const nationInfo = await this.nationList.find(
          (item) => item.country_code === this.loginForm.countryCode
        );
        // this.$http.post('Connect/token', this.loginForm): 返回值为promise
        // 返回值为promise，可加await简化操作 相应的也要加async
        // 创建登录参数对象

        const { data: res } = await this.$http.post("Connect/token", {
          grant_type: this.loginForm.grant_type, // 授权类型
          internationlNumber: nationInfo.international_number, // 国际电话区号
          phone: this.loginForm.phoneNumber, // 手机号
          pwd: this.loginForm.password, // 密码
        });
        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success("登录成功");
        // 1、将登陆成功之后的token, 保存到客户端的sessionStorage中; localStorage中是持久化的保存
        //   1.1 项目中出现了登录之外的其他API接口，必须在登陆之后才能访问
        //   1.2 token 只应在当前网站打开期间生效，所以将token保存在sessionStorage中
        window.sessionStorage.setItem("token", "Bearer " + res.access_token);
        window.sessionStorage.setItem("refresh_token", res.refresh_token);
        // 2、通过编程式导航跳转到后台主页, 路由地址为：/home
        this.$router.push("/home");
      });
    },
  },
};
</script>

<style lang="less">
.body-bg {
  background-attachment: fixed;
  overflow: hidden;
}
</style>

<style lang="less" scoped>
.login_container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.login_box {
  width: 600px;
  height: 400px;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -45%);
  box-shadow: 0 0 10px 3px #ddd;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aeae13;
    font-size: 28px;
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
}
.login_form {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  .el-form-item {
    // 默认是22，控制form-item的间距
    margin-bottom: 30px;
  }
  // 校验提示的样式
  .el-form-item /deep/ .el-form-item__error {
    color: red;
    font-size: 12px;
    padding-top: 4px;
  }
  /* el-input样式穿透 */
  .el-input /deep/ .el-input__inner {
    background-color: #ffffff;
  }
}
/deep/.el-form-item__content {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}
/deep/.el-input {
  font-size: 18px;
}
/deep/.el-input__inner {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}
/deep/.el-input__icon {
  ::before {
    width: 20px;
    height: 20px;
  }
}
.buttonrow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  .el-button {
    font-size: 18px;
  }
}
</style>
