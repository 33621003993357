<template>
  <div class="dataReport">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据统计</el-breadcrumb-item>
      <el-breadcrumb-item>交易报告</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container">
      <div class="block">
        <el-card class="box-card">
          <div>
            <el-form label-width="80px" :model="formLabelAlign">
              <el-row>
                <el-col :span="5">
                  <el-form-item label="开始时间:">
                    <!-- <el-date-picker v-model="formLabelAlign.daterangeTime" type="daterange" range-separator="至"
                      start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker> -->
                    <el-date-picker v-model="formLabelAlign.startime" type="date" placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="结束时间:">
                    <el-date-picker v-model="formLabelAlign.endtime" type="date" placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="商家:">
                    <el-select v-model="formLabelAlign.contents" @change="_getstoreData" placeholder="请选择">
                      <el-option v-for="(item, index) in tenantList" :key="index" :label="item.tenant_name"
                        :value="item.tenant_id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="门店:">
                    <el-select v-model="formLabelAlign.store" multiple collapse-tags placeholder="请选择">
                      <el-option v-for="(item, index) in storeList" :key="index" :label="item.store_name"
                        :value="item.store_id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>


                <el-col :span="3">
                  <el-button type="primary" @click="_getstoreCountDate" icon="el-icon-search">搜索</el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-card>
        <el-card class="box-card">
          <el-row class="demo-box">
            <el-col :span="12">

              <div style="height: 100%; overflow: hidden; ">
                <el-table :data="tableData" header-align="center" style="width: 100%">
                  <el-table-column label="买入" header-align="center">
                    <el-table-column prop="daicheng">
                    </el-table-column>
                    <el-table-column label="款额(港币)" header-align="center" align="right" prop="daijia">
                    </el-table-column>
                    <el-table-column label="交易宗数" header-align="center" align="right" prop="zongshu">
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="卖出" header-align="center">
                    <el-table-column label="款额(港币)" header-align="center" align="right" prop="maichudaijia">
                    </el-table-column>
                    <el-table-column label="交易宗数" header-align="center" align="right" prop="maichuzongshu">
                    </el-table-column>
                  </el-table-column>


                </el-table>

                <el-table :data="tableData2" border header-align="center" style="width: 100%">
                  <el-table-column prop="daicheng" width="120" header-align="center">

                  </el-table-column>
                  <el-table-column label="成功交易总数" header-align="center" align="right" prop="daijia"></el-table-column>
                  <el-table-column label="拒绝交易总数" header-align="center" align="right" prop="zongshu"></el-table-column>
                </el-table>
              </div>
            </el-col>


            <el-col :span="12" style="background-color: brown; ">
              <div style="height: 100%; overflow: hidden; ">
                <el-table :data="tableData3" header-align="center" style="width: 100%">
                  <el-table-column label="汇出的汇款" header-align="center">
                    <el-table-column prop="daicheng">
                    </el-table-column>
                    <el-table-column label="款额(港币)" header-align="center" align="right" prop="huichugangbi">
                    </el-table-column>
                    <el-table-column label="交易宗数" header-align="center" align="right" prop="huichuzongshu">
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="汇入的汇款" header-align="center">
                    <el-table-column label="款额(港币)" header-align="center" align="right" prop="huirugangbi">
                    </el-table-column>
                    <el-table-column label="交易宗数" header-align="center" align="right" prop="huiruzongshu">
                    </el-table-column>
                  </el-table-column>


                </el-table>

                <el-table :data="tableData4" border header-align="center" style="width: 100%">

                  <el-table-column label="成功交易总数" header-align="center" align="right" prop="chenggo"></el-table-column>
                  <el-table-column label="拒绝交易总数" header-align="center" align="right" prop="zongshu"></el-table-column>
                </el-table>
              </div>



            </el-col>

          </el-row>


        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      tableData: [{
        daicheng: '人民币',
      }, {
        daicheng: '其他货币',
      },
      {
        daicheng: '总数',
      }],
      tableData2: [{
        daicheng: '人民币',
      }, {
        daicheng: '其他货币',
      },
      {
        daicheng: '总数',
      }],
      tableData3: [{
        daicheng: '其他金钱服务经营者',
      }, {
        daicheng: '个别人士',
      },
      {
        daicheng: '其他',
      },
      {
        daicheng: '总数',
      }
      ],
      tableData4: [],
      formLabelAlign: {
        store: "",
        daterangeTime: [],
        contents: "",
        startime: this.getThreeMonthsAgo(),
        endtime: this.getCurrentTime(),
      },


      tenantList: [],
      tenantInfo: {},
      storeList: [],
      storeInfo: {},


    };
  },
  mounted() {
    this.tenantList = JSON.parse(localStorage.getItem("loginTenantsInfo"));
    this.tenantInfo = this.tenantList[0];
    this.formLabelAlign.startime = this.getThreeMonthsAgo();
    this.formLabelAlign.endtime = this.getCurrentTime();

  },
  methods: {
    getCurrentTime() {
      const currentDate = moment();
      return currentDate.format('YYYY-MM-DD');
    },
    getThreeMonthsAgo() {
      const currentDate = moment();
      currentDate.subtract(3, 'months');

      return currentDate.format('YYYY-MM-DD');
    },
    // 请求数据
    async fetchData() {
      // 假设有 API 获取数据
      const { data: res } = await this.$http.post("api/Report/Report", {
        st: this.setTime,
        et: this.etTime,
        storeList: ["632915930388762624"],
      });
      console.log(res);
    },
    // 获取门店信息
    _getstoreData() {
      this.$http.get("v1/Store/GetStoreInfoByTenant", {
        params: { tenant: this.formLabelAlign.contents },
      }).then(({ data: res }) => {

        this.storeList = res.data;

      });
    },
    //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    _getstoreCountDate() {
      console.log(this.formLabelAlign.startime, "-------", this.formLabelAlign.endtime, "处理前");
      const startTime = moment(this.formLabelAlign.startime);
      const endTime = moment(this.formLabelAlign.endtime);
      const newStartTime = startTime.format('YYYY-MM-DD') + ' 00:00:00';
      const newEndTime = endTime.format('YYYY-MM-DD') + ' 23:59:59'
      console.log(newStartTime, "-------", newEndTime, "处理后");




      this.$http.post("api/Report/Report", {
        st: newStartTime,
        et: newEndTime,
        storeList: this.formLabelAlign.store,
      }).then(({ data: res }) => {
        if (res.code == -1) return this.$message.error(res.message);
        const countdata = res.data;
        let mairugangbi = [
          {
            daicheng: '人民币',
            daijia: this.formatAmount(countdata.cash2Cash_CNY2HKD_HKDSum),
            zongshu: countdata.cash2Cash_CNY2HKD_Count,
            maichudaijia: this.formatAmount(countdata.cash2Cash_HKD2CNY_HKDSum),
            maichuzongshu: countdata.cash2Cash_HKD2CNY_Count
          }, {
            daicheng: '其他货币',
            daijia: this.formatAmount(countdata.cash2Cash_Other2HKD_HKDSum),
            zongshu: countdata.cash2Cash_Other2HKD_Count,
            maichudaijia: this.formatAmount(countdata.cash2Cash_HKD2Other_HKDSum),
            maichuzongshu: countdata.cash2Cash_HKD2Other_Count,
          },
          {
            daicheng: '总数',
            daijia: this.formatAmount(countdata.cash2Cash_CNY2HKD_HKDSum + countdata.cash2Cash_Other2HKD_HKDSum),
            zongshu: countdata.cash2Cash_CNY2HKD_Count + countdata.cash2Cash_Other2HKD_Count,
            maichudaijia: this.formatAmount(countdata.cash2Cash_HKD2CNY_HKDSum + countdata.cash2Cash_HKD2Other_HKDSum),
            maichuzongshu: countdata.cash2Cash_HKD2CNY_Count + countdata.cash2Cash_HKD2Other_Count
          },
        ];
        this.tableData = mairugangbi;
        let mairugangbi2 = [
          {
            daicheng: '人民币',
            daijia: countdata.cash2Cash_CNY2HKD_Count_12 + countdata.cash2Cash_HKD2CNY_Count_12,
            zongshu: "",

          }, {
            daicheng: '其他货币',
            daijia: countdata.cash2Cash_Other2HKD_Count_12 + countdata.cash2Cash_HKD2Other_Count_12,
            zongshu: "",

          },
          {
            daicheng: '总数',
            daijia: countdata.cash2Cash_CNY2HKD_Count_12 + countdata.cash2Cash_HKD2CNY_Count_12 + countdata.cash2Cash_Other2HKD_Count_12 + countdata.cash2Cash_HKD2Other_Count_12,
            zongshu: "",
          },
        ]
        this.tableData2 = mairugangbi2;
        // 处理金额
        let inputNum = parseFloat(countdata.cash2Trans_All2CNY_CNYSum * 1.098901099);
        let inputNum2 = parseFloat(countdata.surplus2Cash_CNY2All_CNYSum * 1.098901099);
        let roundedInputNum = Math.round(inputNum);
        let roundedInputNum2 = Math.round(inputNum2);
        let mairugangbi3 = [
          {
            daicheng: '其他金钱服务经营者',

          },
          {
            daicheng: '个别人士',
            huichugangbi: this.formatAmount(roundedInputNum), //1
            huichuzongshu: countdata.cash2Trans_All2CNY_Count, //2
            huirugangbi: this.formatAmount(roundedInputNum2), //3
            huiruzongshu: countdata.surplus2Cash_CNY2All_Count, //4 
          },
          {
            daicheng: '其他',
          },
          {
            daicheng: '总数',
          },
        ]
        this.tableData3 = mairugangbi3;
        this.tableData4 =  [
          {
            chenggo: countdata.cash2Trans_All2CNY_Count_8 + countdata.surplus2Cash_CNY2All_Count_8,
            
          }
        ]
      });

    }
  },
};
</script>

<style lang="less" scoped>
.dataReport {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.el-table {
  margin-top: 0;
  font-size: 12px;
}

/deep/ .el-range-separator {
  padding: 0px !important;
}
</style>
