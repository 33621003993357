<template>
  <div class="container">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商户管理</el-breadcrumb-item>
      <el-breadcrumb-item>店铺账户</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="search-box">
        <div class="search-item">
          <div>选择商家：</div>
          <el-popover
            placement="bottom"
            width="200"
            v-model="tenantPopover"
            trigger="click"
          >
            <div class="popover-content">
              <div
                v-for="(item, index) in tenantList"
                :key="index"
                @click="handleTenantChange(item)"
              >
                {{ item.tenant_name }}
              </div>
            </div>
            <el-button slot="reference">{{ tenantInfo.tenant_name }}</el-button>
          </el-popover>
        </div>
        <div class="search-item">
          <div>选择店铺：</div>
          <el-popover
            placement="bottom"
            width="200"
            v-model="storePopover"
            trigger="click"
          >
            <div class="popover-content">
              <div
                v-for="(item, index) in storeList"
                :key="index"
                @click="handleStoreChange(item)"
              >
                {{ item.store_name }}
              </div>
            </div>
            <el-button slot="reference">{{ storeInfo.store_name }}</el-button>
          </el-popover>
        </div>
      </div>
      <div class="account-balance-box">
        <el-tabs v-model="activeTabs" class="tabs-container">
          <el-tab-pane label="1001" name="Legal">
            <div class="currency-box">
              <div
                class="currency-li row"
                @click="changeCurrency(item)"
                v-for="(item, index) in cashBalanceList"
                :key="'cashBalanceList' + index"
              >
                <img
                  style="width: 60px; height: 60px"
                  :src="getImgUrl(item.currency_code)"
                  :alt="item.currency_code"
                />
                <div>
                  <div class="currency-name row">
                    <div>{{ item.currency_code }}</div>
                    <div>{{ item.currency_name }}</div>
                  </div>
                  <div class="currency-name row">
                    <div>余额：</div>
                    <div
                      style="font-size: 28px; font-weight: 700; color: green"
                    >
                      {{ Number(item.balance).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="1009" name="Virtual">
            <div class="currency-box">
              <div
                class="currency-li row"
                @click="changeCurrency(item)"
                v-for="(item, index) in bridgeBalanceList"
                :key="'bridgeBalanceList' + index"
              >
                <img
                  style="width: 60px; height: 60px"
                  :src="getImgUrl(item.currency_code)"
                  :alt="item.currency_code"
                />
                <div>
                  <div class="currency-name row">
                    <div>{{ item.currency_code }}</div>
                    <div>{{ item.currency_name }}</div>
                  </div>
                  <div class="currency-name row">
                    <div>余额：</div>
                    <div
                      style="font-size: 28px; font-weight: 700; color: green"
                    >
                      {{ Number(item.balance).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  watch: {},
  data() {
    return {
      activeTabs: "Legal",
      tenantPopover: false,
      tenantList: [], // 商家列表
      tenantInfo: {}, // 商家信息
      storePopover: false,
      storeList: [], // 店铺列表
      storeInfo: {}, // 店铺信息
      cashBalanceList: [], // 账户列表
      bridgeBalanceList: [], // 账户列表
    };
  },
  onLoad() {},
  mounted() {
    this.tenantList = JSON.parse(localStorage.getItem("loginTenantsInfo"));
    this.tenantInfo = this.tenantList[0];
    this.getStoreList();
  },
  onShow() {},
  methods: {
    changeCurrency(item) {
      this.$router.push({
        path: "/home/balanceList",
        query: {
          currency: item.currency_code,
          tenant: this.tenantInfo.tenant_id,
          store: this.storeInfo.store_id,
        },
      });
    },
    getImgUrl(currencyCode) {
      try {
        return require(`@/assets/currency/${currencyCode}.png`);
      } catch (error) {
        console.error(
          `Image not found for currency code: ${currencyCode}`,
          error
        );
        return ""; // 默认图片
      }
    },
    // 商家选择
    handleTenantChange(item) {
      this.tenantInfo = item;
      this.getStoreList();
      this.tenantPopover = false;
    },
    handleStoreChange(item) {
      this.storeInfo = item;
      this.getAccountBalance();
      this.storePopover = false;
    },
    // 获取商家店铺列表
    async getStoreList() {
      const { data: res } = await this.$http.get(
        "v1/Store/GetStoreInfoByTenant",
        {
          params: { tenant: this.tenantInfo.tenant_id },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      res.data.unshift({
        store_id: "",
        store_name: "全部",
      });
      this.storeList = res.data;
      this.storeInfo = res.data[0];
      this.getAccountBalance();
    },
    // 获取店铺账户列表
    // async getStoreList() {
    //   const { data: res } = await this.$http.get(
    //     "v1/Store/GetStoreInfoByTenant",
    //     {
    //       params: {},
    //     }
    //   );
    //   if (res.code == -1) return this.$message.error(res.message);
    // },
    // 获取账户余额
    async getAccountBalance() {
      const { data: res } = await this.$http.get(
        "StoreAccount/GetStoreAccountBalanceSum",
        {
          params: {
            tenant: this.tenantInfo.tenant_id,
            store: this.storeInfo.store_id,
          },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      this.cashBalanceList = res.cash_balance;
      this.bridgeBalanceList = res.bridge_balance;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .search-box {
      width: 100%;
      display: flex;
      align-items: center;
      .search-item {
        display: flex;
        align-items: center;
        margin-right: 30px;
        > div {
          margin-right: 10px;
        }
      }
    }
    .account-balance-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      /deep/.el-tabs__nav-scroll {
        padding: 10px 0;
      }
      .tabs-container {
        width: 100%;
        height: 100%;
        /deep/.el-tabs__item {
          font-size: 16px;
        }
        .currency-box {
          padding: 20px 5px;
          box-sizing: border-box;
          overflow: auto;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          .currency-li {
            width: 19.4%;
            height: 120px;
            margin: 10px 5px 0;
            cursor: pointer;
            display: flex;
            border: 1px solid #000;
            padding: 0 0 0 20px;
            align-items: center;
            box-sizing: border-box;
            > div {
              color: #333333;
              margin-left: 10px;
            }
            .currency-name {
              padding: 5px 0;
              align-items: center;
              > div {
                height: 30px;
                line-height: 30px;
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
.popover-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;

  > div {
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}
</style>
