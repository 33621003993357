<template>
  <div class="container">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>USDT追踪</el-breadcrumb-item>
      <el-breadcrumb-item>地址</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="header">
        <div class="balance row">
          <div>余额总计:</div>
          <div>
            <div v-for="item in result" :key="item.id">
              {{ item.currency }} - {{ item.chain }}:
              <span style="font-size: 18px; color: red">{{
                item.totalBalance.toLocaleString()
              }}</span>
              。
            </div>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="addAddressTracking"
            >添加地址追踪</el-button
          >
        </div>
      </div>
      <div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :row-key="(row) => row.id"
        >
          <el-table-column label="别名" prop="alisa" />
          <el-table-column label="币种" prop="currency" />
          <el-table-column label="链/协议" prop="chain" />
          <el-table-column label="地址" prop="address" />
          <el-table-column label="余额" prop="balance" />
          <el-table-column label="是否启用">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isEnable">是</el-tag>
              <el-tag type="danger" v-else>否</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button type="text" size="small" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[100, 200, 300, 400, 500]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
      <el-dialog :title="addressTracking" :visible.sync="dialogAddress">
        <el-form :model="form">
          <el-form-item label="别名" label-width="120px">
            <el-input v-model="form.alisa" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="地址" label-width="120px">
            <el-input v-model="form.address" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="币种" label-width="120px">
            <el-select
              v-model="form.currency"
              placeholder="请选择币种"
              @change="handleCurrencyChange"
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="item in currencyList"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="链/协议" label-width="120px">
            <el-select v-model="form.chain" placeholder="请选择链/协议">
              <el-option
                :label="item.protocol"
                :value="item.protocol"
                v-for="item in chainList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用" label-width="120px">
            <el-switch
              v-model="form.isEnable"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="是否是内部地址" label-width="120px">
            <el-switch
              v-model="form.isInner"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogAddress = false">取 消</el-button>
          <el-button type="primary" @click="handleAddAddressTracking"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  watch: {},
  props: {},
  data() {
    return {
      addressTracking: "添加地址追踪",
      dialogAddress: false,
      currentPage: 1,
      form: {
        alisa: "",
        currency: "",
        chain: "",
        address: "",
        isEnable: true,
        isInner: true,
      },
      currencyList: [],
      chainList: [],

      tableData: [],
      page: {
        index: 1,
        size: 100,
        total: 0,
      },
      result: [],
    };
  },
  onLoad() {},
  mounted() {
    this.getTrackingSetting();
    this.getCurrencyList();
  },
  onShow() {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.page.size = val;
      this.getTrackingSetting();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page.index = val;
      this.getTrackingSetting();
    },
    handleClick(row) {
      // TODO: 跳转到某个页面并��带参数
      localStorage.setItem("listData", JSON.stringify(row));
      this.$router.push({
        path: "/home/transactionHistory",
      });
    },
    addAddressTracking() {
      this.dialogAddress = true;
      this.addressTracking = "添加地址追踪";
      this.form = {
        id: "",
        alisa: "",
        currency: "",
        chain: "",
        address: "",
        isEnable: true,
      };
    },
    handleEdit(row) {
      this.dialogAddress = true;
      this.addressTracking = "编辑地址追踪";
      this.form = {
        id: row.id,
        alisa: row.alisa,
        currency: row.currency,
        chain: row.chain,
        address: row.address,
        isEnable: row.isEnable,
      };
    },
    async handleAddAddressTracking() {
      console.log(this.form);
      try {
        // 生成一个16位的随机整数
        const min = Math.pow(2, 15); // 2^15
        const max = Math.pow(2, 16) - 1; // 2^16 - 1
        let randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          "trace-id": randomNum,
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/AddOrUpdateTracking",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/AddOrUpdateTracking",
          this.form,
          { headers }
        );

        // 检查响应数据是否存在
        if (response && response.code == 0) {
          // 处理响应数据
          this.dialogAddress = false;
          this.getTrackingSetting();
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    async handleCurrencyChange(val) {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.get(
          "https://nxapi.seek940.com/foundation/api/Foundation/currenciyProtocol",
          // "http://ttmarket.ttmarket.com.cn:28080/foundation/api/Foundation/currenciyProtocol",
          {},
          { headers }
        );

        // 检查响应数据是否存在
        if (response && response.data) {
          // 处理响应数据
          let data = response.data;
          if (data && data.length > 0) {
            this.chainList = data.filter((item) => {
              return item.code === val;
            }); // 假设数据中有 usdtBalance 属性
          }
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    // axios 获取跟踪设置
    async getTrackingSetting() {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/GetTrackingPageList",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/GetTrackingPageList",
          {
            page: {
              index: this.page.index,
              size: this.page.size,
            },
          },
          { headers }
        );

        // 检查响应数据是否存在
        if (response && response.data) {
          // 处理响应数据
          this.tableData = response.data; // 假设数据中有 usdtBalance 属性
          this.page = response.page; // 假设数据中有 page 属性
          const summedData = this.sumBalancesByCurrencyAndChain(this.tableData);
          this.result = summedData;
          console.log(this.result);
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
      //   this.tableData = response.data.data[0].balance;
    },
    sumBalancesByCurrencyAndChain(data) {
      const resultMap = new Map();

      data.forEach((item) => {
        const key = `${item.currency}_${item.chain}`;

        if (!resultMap.has(key)) {
          resultMap.set(key, {
            currency: item.currency,
            chain: item.chain,
            totalBalance: 0,
            addresses: [],
          });
        }

        const entry = resultMap.get(key);
        entry.totalBalance += parseFloat(item.balance);
        entry.addresses.push(item.address);
      });

      return Array.from(resultMap.values());
    },
    // axios 获取跟踪设置
    async getCurrencyList() {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.get(
          "https://nxapi.seek940.com/foundation/api/Foundation/currencies?type=crypto",
          // "http://ttmarket.ttmarket.com.cn:28080/foundation/api/Foundation/currencies?type=crypto",
          {},
          { headers }
        );

        // 检查响应数据是否存在
        if (response && response.data) {
          // 处理响应数据
          this.currencyList = response.data; // 假设数据中有 usdtBalance 属性
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
      //   this.tableData = response.data.data[0].balance;
    },
  },
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    .search-box {
      display: flex;
      align-items: center;
    }
    .header {
      padding: 10px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .balance {
        display: flex;
        align-items: center;
      }
    }
  }
  /deep/.el-form-item__content {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .el-select {
    display: block;
  }
}
</style>
