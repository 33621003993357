<template>
  <div class="container">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>USDT追踪</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/home/trackingAddress' }">
        地址
      </el-breadcrumb-item>
      <el-breadcrumb-item>交易记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="header">
        <div>钱包地址：{{ listInfo.address }}</div>
        <div>USDT数量：{{ Number(listInfo.balance).toLocaleString() }}</div>
      </div>
      <div class="search-box">
        <div>
          <el-date-picker
            v-model="time"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
            @change="handleDateChange"
          >
          </el-date-picker>
        </div>
        <div class="search-item">
          <div>收支类型</div>
          <el-select v-model="selectValue" placeholder="请选择" class="select">
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="quantity">
          <div>
            <el-input v-model="minNumber" placeholder="最小数量"></el-input>
          </div>
          -
          <div>
            <el-input v-model="maxNumber" placeholder="最大数量"></el-input>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </div>
      </div>

      <div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :row-key="(row) => row.id"
          class="custom-table"
        >
          <el-table-column
            label="创建时间"
            prop="time"
            align="center"
            width="100"
          />
          <el-table-column
            label="交易地址"
            prop="address"
            align="center"
            width="200"
          />
          <el-table-column
            label="币种"
            prop="currency"
            align="center"
            width="100"
          />
          <el-table-column label="交易数量" align="center" width="200">
            <template slot-scope="scope">
              <div
                :style="{
                  color: scope.row.amount > 0 ? 'green' : 'red',
                  fontSize: '18px',
                }"
              >
                {{ Number(scope.row.amount).toLocaleString() }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="汇率"
            prop="rate"
            align="center"
            width="100"
          />
          <el-table-column
            label="计算汇率"
            prop="hkdRate"
            align="center"
            width="100"
          />
          <el-table-column
            label="结果"
            prop="hkdAmount"
            align="center"
            width="200"
          />
          <el-table-column
            label="交易ID哈希"
            prop="txId"
            align="center"
            width="320"
          />
          <el-table-column label="关联客户" align="center">
            <template slot-scope="scope">
              <div class="customer-info">
                <div
                  v-for="(item, index) in scope.row.archives"
                  :key="index"
                  class="customer-item"
                >
                  <div>{{ item.Archives.Name }}</div>
                  <div v-for="(cItem, cIndex) in item.Contacts" :key="cIndex">
                    <div
                      v-for="(cItem2, cIndex2) in cItem.Contacts"
                      :key="cIndex2"
                      class="contact-item"
                    >
                      <div v-if="cItem2.K == 1" class="contact-item-info">
                        <div>手机号码:</div>
                        <div>{{ cItem2.V }}</div>
                      </div>
                      <div v-if="cItem2.K == 2" class="contact-item-info">
                        <div>微信:</div>
                        <div>{{ cItem2.V }}</div>
                      </div>
                      <div v-if="cItem2.K == 3" class="contact-item-info">
                        <div>WhatsApp:</div>
                        <div>{{ cItem2.V }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="状态"
            prop="states"
            align="center"
            width="100"
          />
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleEdit(scope.row)"
                class="edit-btn"
              >
                修改
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="20"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
    <div>
      <el-dialog
        title="计算列表"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <div>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.item1"
              :label="
                '计算汇率：' +
                item.item1 +
                ' ' +
                ' ' +
                ' ' +
                '计算结果：' +
                item.item2
              "
              :value="item.item1 + ' ' + item.item2"
            >
            </el-option>
          </el-select>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleExchangeRate"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  watch: {},
  name: "TransactionHistory",
  data() {
    return {
      id: "",
      centerDialogVisible: false,
      options: [],
      value: "",
      minNumber: "",
      maxNumber: "",
      selectOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "in",
          label: "转入",
        },
        {
          value: "out",
          label: "转出",
        },
      ],
      selectValue: "",
      dialogFormVisible: false,
      listInfo: {},
      form: {
        state: "",
      },
      txId: "",
      currentPage: 1,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
      page: {
        index: 1,
        size: 20,
        total: 0,
      },
      time: "",
      setTime: "",
      etTime: "",
      priceCurrency: "HKD",
    };
  },
  created() {
    this.listInfo = JSON.parse(localStorage.getItem("listData")); // 接收传递的数据
  },
  onLoad() {},
  mounted() {
    this.setTime = this.getTimestampAtStartOfDay();
    this.etTime = this.getEndOfDayTimestamp();
    this.getData();
    this.getStatistics();
  },
  onShow() {},
  methods: {
    async handleExchangeRate() {
      const parts = this.value.split(" ");
      let beforeSpace = "";
      let afterSpace = "";
      if (parts.length > 1) {
        beforeSpace = parts[0];
        afterSpace = parts[1];
      } else {
        beforeSpace = parts[0];
        afterSpace = ""; // 如果没有空格后的部分，设置为空字符串
      }
      const timestamp = Date.now().toString(); // 获取当前时间的时间戳
      const randomPart = Math.floor(Math.random() * 10000).toString(); // 生成一个0-9999之间的随机数
      const uniqueRandom = (timestamp + randomPart).slice(-18); // 取最后18位
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          "trace-id": uniqueRandom,
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/TrackingRecordModifyRate",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/TrackingRecordModifyRate",
          {
            id: this.id,
            hkdRate: beforeSpace, //this.setTime,
            hkdAmount: afterSpace, //this.etTime,
          },
          { headers }
        );
        if (response.code != 0) {
          this.$message.error("修改失败");
          return;
        }
        this.page.pageIndex = 1;
        this.getData();
        this.centerDialogVisible = false;
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    async handleEdit(row) {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 GET 请求
        const { data: response } = await axios.get(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/GenerateExchangeRates?quantity=" +
            row.quantity +
            "&approximateRate=" +
            row.rate,
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/GenerateExchangeRates?quantity=" +
          //   row.quantity +
          //   "&approximateRate=" +
          //   row.rate,
          { headers }
        );
        if (!response && response.length <= 0) {
          this.$message.error("获取转化列表失败");
          return;
        }
        this.options = response;
        this.value = row.hkdRate + " " + row.hkdAmount;
        this.id = row.id;
        this.centerDialogVisible = true;
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    handleSearch() {
      this.page.index = 1;
      this.getData();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.page.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page.index = val;
      this.getData();
    },
    getTimestampAtStartOfDay() {
      let now = new Date();
      now.setHours(0, 0, 1, 0); // 设置为当天的00:00:00
      return Math.floor(now.getTime() / 1000); // 返回秒级时间戳
    },
    getEndOfDayTimestamp() {
      let now = new Date();
      now.setHours(23, 59, 59, 999); // 设置为当天的最后一秒
      return Math.floor(now.getTime() / 1000); // 返回秒级时间戳
    },
    // 日期选择
    handleDateChange(value) {
      if (value) {
        this.setTime = this.convertToTimestamp(value[0]);
        this.etTime = this.convertToTimestamp(value[1]);
      } else {
        this.setTime = this.getTimestampAtStartOfDay();
        this.etTime = this.getEndOfDayTimestamp();
      }
      this.page.pageIndex = 1;
      this.getData();
    },
    convertToTimestamp(dateString) {
      const date = new Date(dateString);
      return Math.floor(date.getTime() / 1000); // 返回秒级时间戳
    },
    calcPrice(record) {
      record.price = 0;
      record.calcPrice = 0;
      if (record.configPrice) {
        const configPrices = JSON.parse(record.configPrice);
        if (configPrices && configPrices.length > 0) {
          const priceCurrency = configPrices.find(
            (p) => p.buy == record.currency && p.sell == this.priceCurrency
          );
          if (priceCurrency) {
            // 计算
            record.rate = priceCurrency.rate;
          }
        }
      }
    },
    calculateNearestExchangeRate(quantity, approximateRate) {
      // 存储不同范围内的汇率
      const rates = [];
      const results = [];

      // 生成浮动范围内的100个汇率
      const step = (approximateRate * 0.5) / 50; // 50% 的浮动
      for (let i = -50; i <= 50; i++) {
        rates.push(approximateRate + i * step);
      }

      // 计算根据每个汇率得到的HKD值
      rates.forEach((rate) => {
        const hkdValue = quantity * rate;
        results.push({
          rate: rate,
          hkdValue: hkdValue,
        });
      });

      // 找到最接近整百、整千和整万的值
      function findNearest(target, step) {
        return Math.round(target / step) * step;
      }

      const nearestValues = results.map((result) => ({
        rate: result.rate,
        nearestHundred: findNearest(result.hkdValue, 100),
        nearestThousand: findNearest(result.hkdValue, 1000),
        nearestTenThousand: findNearest(result.hkdValue, 10000),
      }));

      // 选择最接近的整百、整千、整万
      const bestHundred = nearestValues.reduce((a, b) =>
        Math.abs(a.nearestHundred - quantity * a.rate) <
        Math.abs(b.nearestHundred - quantity * b.rate)
          ? a
          : b
      );
      const bestThousand = nearestValues.reduce((a, b) =>
        Math.abs(a.nearestThousand - quantity * a.rate) <
        Math.abs(b.nearestThousand - quantity * b.rate)
          ? a
          : b
      );
      const bestTenThousand = nearestValues.reduce((a, b) =>
        Math.abs(a.nearestTenThousand - quantity * a.rate) <
        Math.abs(b.nearestTenThousand - quantity * b.rate)
          ? a
          : b
      );
      const candidates = [bestHundred, bestThousand, bestTenThousand];
      const closestRate = candidates.reduce((a, b) =>
        Math.abs(a.rate - approximateRate) < Math.abs(b.rate - approximateRate)
          ? a
          : b
      );
      return closestRate.rate;
    },

    // axios 获取跟踪设置
    async getData() {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/GetTrackingRecordPageList",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/GetTrackingRecordPageList",
          // "http://192.168.3.180/wallet/api/v1/Tracking/GetTrackingRecordPageList",
          {
            page: {
              index: this.page.index,
              size: this.page.size,
            },
            moduleName: "",
            currency: this.listInfo.currency,
            chain: this.listInfo.chain,
            address: this.listInfo.address,
            side: this.selectValue,
            startTime: this.setTime,
            endTime: this.etTime,
            min: this.minNumber,
            max: this.maxNumber,
          },
          { headers }
        );
        if (response.code != 0) {
          this.$message.error("获取跟踪列表失败");
          return;
        }
        // 检查响应数据是否存在
        if (response && response.archives) {
          response.archives.forEach((item) => {
            if (item.archivesFullInfo && item.archivesFullInfo != null) {
              item.archivesInfo = JSON.parse(item.archivesFullInfo);
            }
          });
        }
        if (response && response.data) {
          // 处理响应数据
          response.data.forEach((item) => {
            item.time = new Date(item.createdTime * 1000).toLocaleString();
            item.states = item.state === "none" ? "已完成" : "失败";
            item.archives = [];
            this.calcPrice(item);
            if (this.listInfo.address == item.fromAddress) {
              item.address = item.toAddress;
              item.amount = -Number(item.quantity);
            }
            if (this.listInfo.address == item.toAddress) {
              item.address = item.fromAddress;
              item.amount = Number(item.quantity);
            }
            response.archives.forEach((cItem) => {
              if (
                cItem.address == item.fromAddress &&
                cItem.archivesFullInfo != null
              ) {
                item.archives.push(cItem.archivesInfo);
              }
              if (
                cItem.address == item.toAddress &&
                cItem.archivesFullInfo != null
              ) {
                item.archives.push(cItem.archivesInfo);
              }
            });
          });
          this.tableData = response.data; // 假设数据中有 usdtBalance 属性
          console.log(this.tableData);
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    // axios 获取交易金额
    async getStatistics() {
      const timestamp = Date.now().toString(); // 获取当前时间的时间戳
      const randomPart = Math.floor(Math.random() * 10000).toString(); // 生成一个0-9999之间的随机数
      const uniqueRandom = (timestamp + randomPart).slice(-18); // 取最后18位
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          "trace-id": uniqueRandom,
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/Statistics",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/Statistics",
          // "http://192.168.3.180/wallet/api/v1/Tracking/Statistics",
          {
            page: {
              index: this.page.index,
              size: this.page.size,
            },
            moduleName: "",
            currency: this.listInfo.currency,
            chain: this.listInfo.chain,
            address: this.listInfo.address,
            side: this.selectValue,
            startTime: this.setTime,
            endTime: this.etTime,
          },
          { headers }
        );
        if (response.code != 0) {
          this.$message.error("获取统计数据失败");
          return;
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    height: 100%;
    .search-box {
      display: flex;
      padding: 0;
      align-items: center;
      > div {
        margin-right: 30px;
      }
      .search-item {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        background-color: #fff;
        border-radius: 5px;
        > div {
          padding: 0 10px;
        }
        .select {
          width: 100px;
          /deep/ input {
            width: 100%;
            border: none;
            outline: none;
            background: transparent;
          }
        }
      }
      .quantity {
        display: flex;
        align-items: center;
        > div {
          margin: 0 10px;
        }
      }
    }
    .header {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      > div {
        padding: 5px 0;
      }
    }
  }
  /deep/.el-form-item__content {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .el-select {
    display: block;
  }
  .customer-info {
    .customer-item {
      display: flex;
      align-items: center;
      padding: 0 5px;
      .contact-item {
        display: flex;
        align-items: center;
        padding: 5px;
        .contact-item-info {
          display: flex;
          align-items: center;
          padding: 0 5px;
        }
      }
    }
  }
  /deep/.el-table__header-wrapper {
    tr {
      flex: 1;
    }
  }
  .custom-table ::v-deep .el-table__cell {
    font-size: 16px !important;
  }
  .edit-btn {
    font-size: 16px;
  }
}
</style>
